import React from "react"

import Layout from "~/components/common/Layout"
import { getBackLink, getHeaderTitles, getNextLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"
import { graphql, useStaticQuery } from "gatsby"
import HeroVideo from "~/components/page/HeroVideo"
import { css } from "@emotion/core"
import { Text, Ul } from "~/components/common/Typography"
import { Box, Flex } from "reflexbox"
import Img from "gatsby-image"
import { mq } from "~/components/common/theme"
import { InfoboxDetailed } from "~/components/page/Infobox"
import GraphicWithText from "~/components/page/GraphicWithText"
import Copyright from "~/components/common/Copyright"

import fistGif from "~/images/5.2/5.2a_ADIDAS_DC_WW.gif"
import Source from "~/components/common/Source"

const Page = ({ myPage = 4, mySubpage = 1 }) => {
  const data = useStaticQuery(graphql`
      query {
          hero: file(name: { eq: "5.2HERO" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic1: file(name: { eq: "5.2b-ADIDAS-demo1" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic2: file(name: { eq: "5.2c-ADIDAS-demo2" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic3: file(name: { eq: "5.2d-ADIDAS-demo3" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          track: file(name: { eq: "5.2e-ADIDAS-track" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          how: file(name: { eq: "5.2f-ADIDAS-how" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `)

  const { hero, graphic1, graphic2, graphic3, track, how } = data

  const graphicsData = [
    {
      image: graphic1,
      text: `Demo-show-demo means you are demonstrating the correct way to perform a skill in terms of form, technique, and how it should look. Then you show the player what they are doing, and how it is different. Finally, you demonstrate the correct way again to reinforce the difference and show the player what she should be working toward.`,
    },
    {
      image: graphic2,
      text: `Girls will listen! Girls listen because they want to get it right. It is important to them to listen and do things well, because they want to be socially accepted, and social acceptance is one of a girl’s main reasons for getting involved in a sport.`,
      source: "36",
    },
    {
      image: graphic3,
      title: "The result:",
      text: `You must include all three parts in order to show the difference and reinforce the correct form. Make sure to involve movement, which helps girls learn with their own bodies and prevents confusion or misunderstanding that could occur through exclusively verbal instructions.`,
    },
  ]

  const graphicMq = mq({
    width: ["100%"],
  })

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge="In order to skill-build, you as a coach must understand the foundation upon which each girl is working."
        text={
          <span>
            You can do so by sparking a dialogue about how they are feeling
            emotionally and physically. We call this{" "}
            <span
              css={css`
                font-family: "AdineuePROBoldWeb";
              `}
            >
              checking in.
            </span>
          </span>
        }
        image={hero}
        bg={"white"}
      />

      <InfoboxDetailed
        headerText={"How to check in"}
        maxWidth={"100%"}
        contentMaxWidth={"desktop"}
      >
        <Flex flexDirection={"column"}>
          <Box px={5} mt={6} maxWidth={"mediumContent"} mx={"auto"}>
            <Text
              uppercase
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "29px"]}
              fontFamily={"AdineuePROBlackWeb"}
              color={"grey-text"}
              mt={0}
              mb={2}
            >
              Use the one-to-five check in method
            </Text>
            <Text>
              It’s simple: You can ask your players, “On a scale of one-to-five,
              five being great and one being bad, how are you feeling?”
            </Text>
          </Box>

          <Flex flexDirection={["column", "column", "row"]} mt={6}>
            <Flex width={["100%", "100%", "50%"]}>
              <Box width={"100%"} mb={[5, 0]} mt={4}>
                <img
                  src={fistGif}
                  css={css`
                    width: 100%;
                  `}
                ></img>
              </Box>
            </Flex>
            <Box width={["100%", "100%", "50%"]} px={6} textAlign={"left"}>
              <Text
                uppercase
                fontSize={["16px", "20px"]}
                lineHeight={["22px", "24px"]}
                fontFamily={"AdineuePROBoldWeb"}
                mt={0}
                mb={2}
              >
                Providing and defining the scale
              </Text>
              <Text mt={0}>
                This simple five-point scale helps girls give you an accurate
                representation of how they are feeling. Sometimes, girls don’t
                have the words to describe emotions, so enumerating their
                feelings can help make expressing them easier. One-to-five also
                prevents less insightful answers such as “OK” or “fine.”
              </Text>
              <Text
                uppercase
                fontSize={["16px", "20px"]}
                lineHeight={["22px", "24px"]}
                fontFamily={"AdineuePROBoldWeb"}
                mt={0}
                mb={2}
              >
                Physical representation, using fingers and hands
              </Text>
              <Text mt={0}>
                The numeric scale also allows for the check-in to be nonverbal.
                Players can use their fingers to convey how they are feeling,
                which can help in sports when they are far away from you on the
                field. It also helps girls who are shy to share their feelings
                more easily.
              </Text>
              <Text
                uppercase
                fontSize={["16px", "20px"]}
                lineHeight={["22px", "24px"]}
                fontFamily={"AdineuePROBoldWeb"}
                mt={0}
                mb={2}
              >
                Can be used for sport and life skills
              </Text>
              <Text mt={0}>
                Don’t only use one-to-five to ask about how players thought a
                drill or scrimmage went. Ask an athlete how her day was or how
                school is going. This feedback helps you understand what an
                athlete might need from you and gives you more information about
                her context outside of the team.
              </Text>
              <Text>
                The technique can measure how a player feels about her mastery
                of a sports skill, which informs what type of support you should
                provide as a coach. If she’s at a five, try something new. If
                she’s at a two, it’s time to help.
              </Text>
            </Box>
          </Flex>
        </Flex>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={"Showing girls how"}
        maxWidth={"100%"}
        bg={"seafoam"}
        headerBackground={"transparent"}
        contentMaxWidth={"desktop"}
      >
        <Box bg={"white-panel"} mt="5" py={[6, 6]}>
          <Flex
            flexDirection={["column"]}
            mt={4}
            maxWidth={"tablet"}
            mx={"auto"}
          >
            <Text mt={0}>
              Once you know how an athlete is feeling, you can assess the best
              way to communicate with them and how to skill-build.
            </Text>
            <Text
              uppercase
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "29px"]}
              fontFamily={"AdineuePROBlackWeb"}
              color={"grey-text"}
              mt={0}
              mb={5}
            >
              The demo-show-demo method
            </Text>
          </Flex>
          <Flex
            mx={"auto"}
            width={"100%"}
            justifyContent={"justify-around"}
            alignItems={"start"}
            css={css`
              flex-wrap: wrap;
            `}
          >
            {graphicsData.map((item, index) => (
              <GraphicWithText
                mq={graphicMq}
                image={item.image}
                width={["100%", "33%"]}
                key={index}
                px={6}
              >
                <Box fontSize={["16px", "16px"]} lineHeight={["24px", "27px"]}>
                  <Text my={0}>
                    {item.text}
                    {item.source && <Source>{item.source}</Source>}
                  </Text>
                </Box>
              </GraphicWithText>
            ))}
          </Flex>
        </Box>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={"How did you do that?"}
        maxWidth={"100%"}
        contentMaxWidth={"mediumContent"}
      >
        <Flex flexDirection={"column"} mt={5}>
          <Text mt={0}>
            When a girl does something new, accomplishes something she’s been
            working on, or overcomes a challenge, you should ask, “How did you
            do that?” This question promotes reflection, and reflection leads to
            growth.<Source>37</Source>
          </Text>

          <Box width={"100%"} mb={[5, 0]} mt={4}>
            <Img
              fluid={track.childImageSharp.fluid}
              margin={"0 auto"}
              loading={"eager"}
            ></Img>
          </Box>

          <Text>
            Asking “How did you do that?” allows girls to think about the
            process they took to be successful. It focuses on the steps that
            were involved in reaching this point.
          </Text>
          <Text
            uppercase
            fontSize={["18px", "24px"]}
            lineHeight={["27px", "27px"]}
            fontFamily={"AdineuePROBlackWeb"}
            color={"grey-text"}
          >
            Focusing on the process shows two important things:
          </Text>
          <Text mt={0}>
            First, that success was not the result of luck but rather the result
            of hard work, effort, and intentional action on the part of the
            player. It shows that the action can be repeated.
          </Text>
          <Text mt={0}>
            Second, by identifying the process, girls can replicate the steps to
            be successful in sports and transfer them to other areas of life.
          </Text>
          <Text mt={0}>
            You should ask the question a few times. Initial reactions might be,
            “I don't know,” or, “I got lucky.” By asking multiple times, and
            helping guide players to look inward, we help ensure they understand
            how they improved.
          </Text>
          <Box width={"100%"} mb={[5, 4]} mt={4}>
            <Img
              fluid={how.childImageSharp.fluid}
              margin={"0 auto"}
              loading={"eager"}
            ></Img>
          </Box>
          <Text
            uppercase
            fontSize={["18px", "24px"]}
            lineHeight={["27px", "27px"]}
            fontFamily={"AdineuePROBlackWeb"}
            color={"grey-text"}
            mb={0}
          >
            Asking the question “How did you do that?”
          </Text>
          <Ul
            as={"ol"}
            textAlign={"left"}
            mt={0}
            css={css`
              list-style-type: decimal;
              list-style-position: inside;
            `}
          >
            <li>A player makes a perfect pass</li>
            <li>You ask her, “How did you do that?”</li>
            <li>
              The player responds, “Well, I practiced after class and watched
              videos on YouTube.”
            </li>
          </Ul>
          <Text
            uppercase
            fontSize={["18px", "24px"]}
            lineHeight={["27px", "27px"]}
            fontFamily={"AdineuePROBlackWeb"}
            color={"grey-text"}
          >
            These strategies, in addition to other tactics you may already be
            using, will help you further support your players’ development as an
            effective coach and mentor.
          </Text>
        </Flex>
      </InfoboxDetailed>

      <Copyright/>
    </Layout>
  )
}

export default withProgress(Page, 4, 1)
